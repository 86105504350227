<template>
  <vuestic-widget>
    <div class="container">
      <div class="row">
        <div class="form-group full-width">
          <div class="input-group">
            <input
              v-model="pergunta.pergunta"
              id="id-input-pergunta"
              required
            />
            <label
              for="id-input-pergunta"
              class="control-label"
            >
              Pergunta
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="form-group full-width">
              <vuestic-radio-button
                class="mr-2 mt-2"
                option="1"
                label=" "
                value
                v-model="pergunta.tipos_de_respostas"
              />
              <div class="full-width">
                <img
                  class="icons"
                  src="../../../assets/icons/rosto-sorrindo.svg"
                  alt=""
                /><img
                  class="icons"
                  src="../../../assets/icons/rosto-normal.svg"
                  alt=""
                /><img
                  class="icons"
                  src="../../../assets/icons/rosto-raiva.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group full-width">
              <vuestic-radio-button
                class="mr-2 mt-2"
                option="2"
                label=" "
                value
                v-model="pergunta.tipos_de_respostas"
              />
              <div class="full-width">
                <img
                  class="icons"
                  src="../../../assets/icons/rosto-sorrindo.svg"
                  alt=""
                /><img
                  class="icons"
                  src="../../../assets/icons/rosto-raiva.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <vuestic-radio-button
                class="mr-2 mt-2"
                option="3"
                label=" "
                value
                v-model="pergunta.tipos_de_respostas"
              />
              <div class="row ml-1">
                <div class="box-question class-sim mr-2">
                  Sim
                </div>
                <div class="box-question class-nao">
                  Não
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="form-group">
              <vuestic-checkbox v-model="pergunta.acao" />
              <div class="box-question box-color-gray">
                Plano de Ação
              </div>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="form-group">
              <vuestic-checkbox v-model="pergunta.editar" />
              <i class="box-question box-color-gray">
                <i class="el-icon-edit-outline"></i>
              </i>
            </div>
          </div>
          -->
          <div class="row">
            <div class="form-group">
              <vuestic-checkbox v-model="pergunta.imagem" />
              <i class="box-question box-color-gray">
                <i class="el-icon-camera"></i>
              </i>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="form-group">
              <vuestic-checkbox v-model="pergunta.obrigatorio" />
              <i class="">
                Obrigatorio?
              </i>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <vuestic-checkbox v-model="pergunta.nao_se_aplica" />
              <div class="box-question class-n-a">
                <strong>N/A</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group">
          <vuestic-radio-button
            class="mr-2 mt-2"
            option="4"
            label=" "
            value
            v-model="pergunta.tipos_de_respostas"
          />
          <multiselect
            :disabled="disableCampoText"
            v-model="formatacaoSelecionada"
            placeholder="Campo de Texto"
            track-by="id"
            label="nome"
            :options="typeQuestionsMask"
            :multiple="false"
          />
        </div>
      </div>
      <!--
      <div class="row">
        <div class="form-group">
          <vuestic-radio-button
            class="mr-2 mt-2"
            option="5"
            label="Respostas personalizadas (criar opções)"
            v-model="pergunta.tipos_de_respostas"
          />
        </div>
        <div
          v-if="pergunta.tipos_de_respostas == 5"
          class="container"
        >
          <div class="row">
            <div class="col-md-3">
              <div>Múltipla escolha</div>
              <multiselect
                v-model="areaChecklist"
                placeholder="Selecione"
                :options="['Sim', 'Não']"
                selectedLabel="Selecionado"
                select-label="Selecione uma área"
                deselect-label="Remover área"
              >
                <span slot="noResult">Área não encontrado.</span>
              </multiselect>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-5">
              <div class="form-group full-width">
                <div class="input-group">
                  <input
                    v-model="multiplaPerguntas.nome"
                    id="id-input-pergunta"
                    required
                  />
                  <label
                    for="id-input-pergunta"
                    class="control-label"
                  >
                    Pergunta
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group full-width">
                <div class="input-group">
                  <input

                    v-model="multiplaPerguntas.valor"
                    id="id-input-pergunta"
                    required
                    type="number"
                  />
                  <label
                    for="id-input-pergunta"
                    class="control-label"
                  >
                    Valor
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-1">
              <el-button
                @click="addPergunta"
                type="primary"
              >Adicionar</el-button>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-8">
              <data-tables
                :data="multiplasPerguntas"
                :action-col="actionCol"
                :table-props="tableProps"
                layout="table"
              >
                <el-table-column
                  prop="nome"
                  label="NOME"
                  width="400"
                />
                <el-table-column
                  prop="valor"
                  label="VALOR"
                >
                  <template
                    slot-scope="row"
                    style="width: 100%;"
                  >
                    <div>
                      <template v-if="row.row.valor">
                        {{row.row.valor}}
                      </template>\
                      <template v-else>__</template>
                    </div>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
      -->
      <div class="row">
        <div class="form-group full-width">
          <div class="input-group">
            <textarea
              v-model="pergunta.texto_explicativo"
              id="id-input-pergunta"
              required
            />
            <label
              for="id-input-pergunta"
              class="control-label"
            >
              Texto explicativo. (ajuda para o Consultor de Campo)
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-end">
          <button
            class="btn btn-pale btn-micro mr-2"
            @click="backToFormCheckList()"
          >Voltar
          </button>
          <button
            @click="postOrPut"
            class="btn btn-primary btn-micro"
          >Salvar
          </button>
        </div>
      </div>
    </div>
  </vuestic-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    routerSuffix: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      idChecklist: null,
      paramsRoute: {},
      pergunta: {
        pergunta: '',
        tipos_de_respostas: '1',
        formatacao: null,
        nao_se_aplica: false,
        acao: false,
        imagem: false,
        obrigatorio: false,
        texto_explicativo: '',
      },
      areaChecklist: [],
      multiplaPerguntas: {
        nome: '',
        valor: null,
      },
      formatacaoSelecionada: null,
      multiplasPerguntas: [],
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'REMOVER',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              console.info(row);
            },
          },
        ],
      },
    };
  },
  created() {
    this.pergunta.id = this.$router.history.current.params.id;
    this.idArea = this.$router.history.current.params.area;
    this.getAll();
  },
  methods: {
    ...mapActions('pergunta', [
      'getPerguntaById',
      'putPergunta',
      'postPergunta',
    ]),
    ...mapActions('areaDaChecklist', [
      'getAreaById',
      'putArea',
    ]),
    postOrPut() {
      if (this.pergunta.id) {
        this.editarPergunta();
      } else {
        this.cadastrarPergunta();
      }
    },
    async editarPergunta() {
      try {
        await this.putPergunta(this.pergunta);
        this.message('success', 'Pergunta Alterada com sucesso');
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.message('error', 'Erro na operação');
      }
    },
    async getAll() {
      try {
        if (this.pergunta.id) {
          this.pergunta = await this.getPerguntaById(this.pergunta.id);
          this.pergunta.tipos_de_respostas = String(this.pergunta.tipos_de_respostas);
        } else {
          this.areaChecklist = await this.getAreaById(this.idArea);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async cadastrarPergunta() {
      try {
        const res = await this.postPergunta(this.pergunta);
        this.atualizarArea(res.id);
        this.clearForm();
        this.message('success', 'Pergunta cadastrada com sucesso');
      } catch (error) {
        this.message('error', 'Erro na operação');
        console.info(error);
      }
    },
    async atualizarArea(id) {
      this.areaChecklist.pergunta.push(id);
      try {
        const res = await this.putArea(this.areaChecklist);
        setTimeout(() => {
          this.message('success', 'Área atualizada');
        }, 2000);
        this.areaChecklist = res;
      } catch (error) {
        this.message('error', 'Erro ao atualizar Área');
      }
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    addPergunta() {
      this.multiplasPerguntas.push(this.multiplaPerguntas);
      this.multiplaPerguntas = {};
    },
    backToFormCheckList() {
      const id = this.$router.history.current.params.checklist;
      this.$router.push({ name: `cadastrar-checklist-id${this.routerSuffix}`, params: { checklist: id } });
    },
    clearForm() {
      this.pergunta = {
        pergunta: '',
        tipos_de_respostas: '1',
        formatacao: null,
        nao_se_aplica: false,
        acao: false,
        imagem: false,
        obrigatorio: false,
        texto_explicativo: '',
      };
    },
  },
  watch: {
    formatacaoSelecionada(val) {
      this.pergunta.formatacao = val ? val.id : null;
      if (!this.disableCampoText && val === null) {
        this.formatacaoSelecionada = { id: 1, nome: 'Texto sem formatação' };
      }
    },
    disableCampoText(val) {
      if (val) {
        this.formatacaoSelecionada = null;
      } else if (!this.formatacaoSelecionada) {
        this.formatacaoSelecionada = { id: 1, nome: 'Texto sem formatação' };
      }
    },
  },
  computed: {
    ...mapGetters(['typeQuestionsMask']),
    disableCampoText() {
      return this.pergunta.tipos_de_respostas !== '4';
    },
  },
};
</script>

<style scoped>
.el-button {
	height: 64%;
}
.vuestic-radio-button__slot-container {
	padding-top: 0.1rem !important;
}
.icons {
	width: 50px;
}
.box-color-gray {
	background-color: #f5f7fa;
	border-color: #e4e7ed;
	color: #97999e;
}
.el-table th,
.el-table tr {
	background-color: #4eb577 !important;
}
</style>
